import * as React from 'react';
import {Helmet} from 'react-helmet';

import Layout from '@components/layouts/layout';
import Navigation from '@components/navigation/navigation';
import Footer from '@components/footer/footer';

import {Container, Row, Col, Image} from 'react-bootstrap';

import {Instagram, Facebook, Linkedin, Youtube, Github, Behance, Tiktok, Twitter} from 'react-bootstrap-icons';
import {Link, useIntl} from 'gatsby-plugin-intl';

const mapper = {
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  tiktok: <Tiktok />,
  linkedin: <Linkedin />,
  youtube: <Youtube />,
  github: <Github />,
  itchio: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{width: '36px', height: '36px'}}>
      <path d="M71.9 34.8C50.2 47.7 7.4 96.8 7 109.7v21.3c0 27.1 25.3 50.8 48.3 50.8 27.6 0 50.5-22.9 50.5-50 0 27.1 22.2 50 49.8 50s49-22.9 49-50c0 27.1 23.6 50 51.2 50h.5c27.6 0 51.2-22.9 51.2-50 0 27.1 21.5 50 49 50s49.8-22.9 49.8-50c0 27.1 23 50 50.5 50 23 0 48.3-23.8 48.3-50.8v-21.3c-.4-12.9-43.2-62.1-64.9-75C372.6 32.4 325.8 32 256 32S91.1 33.1 71.9 34.8zm132.3 134.4c-22 38.4-77.9 38.7-99.9 .3-13.2 23.1-43.2 32.1-56 27.7-3.9 40.2-13.7 237.1 17.7 269.2 80 18.7 302.1 18.1 379.8 0 31.7-32.3 21.3-232 17.8-269.2-12.9 4.4-42.9-4.6-56-27.7-22 38.5-77.9 38.1-99.9-.2-7.1 12.5-23.1 28.9-51.8 28.9a57.5 57.5 0 0 1 -51.8-28.9zm-41.6 53.8c16.5 0 31.1 0 49.2 19.8a436.9 436.9 0 0 1 88.2 0C318.2 223 332.9 223 349.3 223c52.3 0 65.2 77.5 83.9 144.5 17.3 62.2-5.5 63.7-34 63.7-42.2-1.6-65.5-32.2-65.5-62.8-39.3 6.4-101.9 8.8-155.6 0 0 30.6-23.3 61.2-65.5 62.8-28.4-.1-51.2-1.6-33.9-63.7 18.7-67 31.6-144.5 83.9-144.5zM256 270.8s-44.4 40.8-52.4 55.2l29-1.2v25.3c0 1.6 21.3 .2 23.3 .2 11.7 .5 23.3 1 23.3-.2v-25.3l29 1.2c-8-14.5-52.4-55.2-52.4-55.2z" />
    </svg>
  ),
  behance: <Behance />,
};

const KoloNaukoweSinglePage = ({pageContext}) => {
  const {locale} = useIntl();

  const kn = pageContext.kn;

  const data =
    locale === 'pl'
      ? {
          desc: kn.desc.pl,
        }
      : {
          desc: kn.desc.en || kn.desc.pl,
        };

  const hasTags = () => {
    return Array.isArray(kn.tags) && kn.tags.length > 0;
  };

  const formatWebsite = url => {
    return (
      url
        // Step 1: strip http|s from start
        .replace(/(^\w+:|^)\/\//, '')
        // Step 2: remove www.
        .replace(/(www.)/, '')
        // Step 3: remove trailing slash
        .replace(/\/$/, '')
    );
  };

  const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Layout>
      <Helmet>
        <title>{kn.name} – Koła naukowe – Studenckie Koła Naukowe AGH</title>
        <meta property="og:title" content={`${kn.name} – Koła naukowe – Studenckie Koła Naukowe AGH`} />
        {kn.img && <meta property="og:image" content={`https:${kn.img}`} />}
      </Helmet>

      <Navigation customPagesNav={pageContext.customPagesNav} />
      <div className="kola-naukowe-single pt-5">
        <Container className="py-lg">
          <Row className="mb-5">
            <Col>
              <Link to="/kola-naukowe" className="kola-naukowe-single__back">
                <button>&larr;</button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              {kn.logo && (
                <img src={kn.logo} className="kola-naukowe-single__logo" alt={`Logo koła naukowego ${kn.name}`} />
              )}
              <h1 className="mb-5">{kn.name}</h1>
              <p>{data.desc}</p>
            </Col>
            <Col md={1}></Col>
            <Col md={4} className="pt-lg">
              <div className="kola-naukowe-single__green-box">
                {kn.socials && (
                  <p className="kola-naukowe-single__green-box--social-media">
                    <span className="label">Social media</span>
                    {Object.keys(kn.socials).map((key, index) => {
                      if (kn.socials[key]) {
                        return (
                          <a
                            key={index}
                            href={kn.socials[key]}
                            target="_blank"
                            rel="noreferrer"
                            className="kola-naukowe-single__green-box--social-media-icon"
                          >
                            {mapper[key]}
                          </a>
                        );
                      }
                      return null;
                    })}
                  </p>
                )}
                {kn.website && (
                  <p>
                    <span className="label">Strona internetowa</span>
                    <a className="text-wrap" href={kn.website} target="_blank" rel="noreferrer">
                      {formatWebsite(kn.website)}
                    </a>
                  </p>
                )}
                {kn.email && (
                  <p>
                    <span className="label">Adres e-mail</span>
                    <a className="text-wrap" href={`mailto:${kn.email}`}>
                      {kn.email}
                    </a>
                  </p>
                )}
                {kn.area && (
                  <p>
                    <span className="label">Obszar</span>
                    <span>{capitalize(kn.area)}</span>
                  </p>
                )}
                {hasTags() && (
                  <p className="kola-naukowe-single__green-box--tags">
                    <span className="label">Tagi</span>
                    {kn.tags.map((tag, index) => (
                      <span key={index}>#{tag}</span>
                    ))}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          {kn.img && (
            <Row className="pt-5">
              <Col md={7}>
                <Image src={kn.img} fluid rounded />
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <Footer />
    </Layout>
  );
};

export default KoloNaukoweSinglePage;
